<template xmlns:b-row="http://www.w3.org/1999/html">
  <section id="crm-user-edit-addresses">
    <validation-observer ref="rulesUser">
      <div
        v-for="(address, index) in activeAddresses"
        :key="`address-${index}`"
        :class="{ 'mt-2': index > 0}"
      >
        <div
          class="border-left-3 d-flex align-items-center"
          :class="{'border-left-primary': index % 2 === 0, 'border-left-secondary': index % 2 !== 0}"
        >
          <div
            class="mr-1"
            style="writing-mode: vertical-lr; text-orientation: mixed;"
          >
            Endereço {{ index + 1 }}
          </div>

          <b-row>
            <b-col cols="4">
              <zip-code-input
                :zip-code.sync="address.zip_code"
                :is-submiting="isSubmiting"
                @response="(event) => zipCodeResponse(event, index)"
              />
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Estado"
                :label-for="`state-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="address.removed ? '' : 'required'"
                >
                  <b-form-select
                    v-model="address.state"
                    :options="userStates"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Cidade"
                :label-for="`city-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="address.removed ? '' : 'required'"
                >
                  <b-form-input
                    :ref="`city-${index}`"
                    v-model="address.city"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Bairro"
                :label-for="`neighbor-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="address.removed ? '' : 'required'"
                >
                  <b-form-input
                    :ref="`neighbor-${index}`"
                    v-model="address.neighbor"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Rua"
                :label-for="`street-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="address.removed ? '' : 'required'"
                >
                  <b-form-input
                    :ref="`street-${index}`"
                    v-model="address.street"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Número"
                :label-for="`number-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="address.removed ? '' : 'required'"
                >
                  <b-form-input
                    :ref="`number-${index}`"
                    v-model="address.number"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                :label="index === 0 ? 'Complemento' : ''"
                :label-for="`complement-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-input
                    :id="`complement-${index}`"
                    v-model="address.complement"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                :label="index === 0 ? 'Descrição' : ''"
                :label-for="`description-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-input
                    :id="`description-${index}`"
                    v-model="address.description"
                    :class="{'line-through': address.removed}"
                    :disabled="isSubmiting || address.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="ml-2">
            <b-button
              variant="outline-primary"
              :class="{ 'mt-2': index === 0 }"
              @click="removeAddress(address)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </div>
    </validation-observer>

    <div class="d-flex">
      <b-button
        size="sm"
        @click="addAddresses"
      >
        Adicionar Endereço
      </b-button>
    </div>

  </section>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  // BInputGroup,
  // BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { userStates } from '@/utils/options'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import requestErrorMessage from '@/mixins/requestErrorMessage'
import ZipCodeInput from '@/views/cms/components/inputs/ZipCodeInput.vue'

export default {
  components: {
    ZipCodeInput,
    BFormGroup,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BRow,
    BCol,
    // BInputGroup,
    // BInputGroupAppend,
  },
  mixins: [toasts, swall, requestErrorMessage],

  props: {
    addresses: {
      type: Array,
      default: () => [],
      required: true,
    },

    userId: {
      type: Number,
      required: true,
    },

    isSubmiting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isRemoving: false,
      userData: null,
      userStates,
    }
  },
  computed: {
    activeAddresses() {
      return this.addresses
    },

    allAddresses() {
      return JSON.stringify(this.addresses)
    },
  },

  watch: {
    allAddresses(newValues, oldValues) {
      const newV = JSON.parse(newValues)
      const oldV = JSON.parse(oldValues)

      if (oldV) {
        newV.forEach((item, index) => {
          if (JSON.stringify(item) !== JSON.stringify(oldV[index])) {
            this.addresses[index].changed = true
          }
        })
        this.$emit('update:addresses', this.addresses)
      }
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)
  },

  methods: {
    zipCodeResponse(event, index) {
      const street = event.logradouro.split('-')[0]
      this.addresses[index].city = event.localidade
      this.addresses[index].street = street
      this.addresses[index].state = event.uf
      this.addresses[index].neighbor = event.bairro
    },

    removeAddress(address) {
      let addresses = []
      if (address.created ?? false) {
        addresses = this.addresses.filter(f => f !== address)
      } else {
        addresses = this.addresses.map(m => ({
          ...m,
          removed: m.removed || m === address,
        }))
      }

      this.$emit('update:addresses', addresses)
    },

    addAddresses() {
      this.addresses.push({
        id: 0,
        street: '',
        number: '',
        complement: '',
        neighbor: '',
        city: '',
        state: '',
        zip_code: '',
        description: '',
        user_id: this.userId,
        created: true,
      })
    },
  },
}
</script>
