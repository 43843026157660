<template xmlns:b-row="http://www.w3.org/1999/html">
  <section id="crm-user-edit-emails">
    <validation-observer ref="rulesUser">
      <b-row
        v-for="(email, index) in activeEmails"
        :key="`email-${index}`"
      >
        <b-col cols="12">
          <b-row>
            <b-col cols="6">
              <b-form-group
                :label="index === 0 ? 'E-mail' : ''"
                :label-for="`email-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="email.removed ? '' : 'required'"
                >
                  <b-form-input
                    v-model="email.email"
                    placeholder="E-mail"
                    :class="{'line-through': email.removed}"
                    :disabled="isSubmiting || email.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                :label="index === 0 ? 'Descrição' : ''"
                :label-for="`description-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-input
                    :id="`description-${index}`"
                    v-model="email.description"
                    :class="{'line-through': email.removed}"
                    :disabled="isSubmiting || email.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-button
                variant="outline-primary"
                :class="{ 'mt-2': index === 0 }"
                @click="removeEmail(email)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </validation-observer>

    <div class="d-flex">
      <b-button
        size="sm"
        @click="addEmail"
      >
        Adicionar E-mail
      </b-button>
    </div>

  </section>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import requestErrorMessage from '@/mixins/requestErrorMessage'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BRow,
    BCol,
  },
  mixins: [toasts, swall, requestErrorMessage],

  props: {
    emails: {
      type: Array,
      default: () => [],
      required: true,
    },

    userId: {
      type: Number,
      required: true,
    },

    isSubmiting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isRemoving: false,
      userData: null,
    }
  },
  computed: {
    activeEmails() {
      return this.emails
    },

    allEmails() {
      return JSON.stringify(this.emails)
    },
  },

  watch: {
    allEmails(newValues, oldValues) {
      const newV = JSON.parse(newValues)
      const oldV = JSON.parse(oldValues)

      if (oldV) {
        newV.forEach((item, index) => {
          if (JSON.stringify(item) !== JSON.stringify(oldV[index])) {
            this.emails[index].changed = true
          }
        })
        this.$emit('update:emails', this.emails)
      }
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)
  },

  methods: {
    removeEmail(email) {
      let emails = {}
      if (email.created ?? false) {
        emails = this.emails.filter(f => f !== email)
      } else {
        emails = this.emails.map(m => ({
          ...m,
          removed: m.removed || m === email,
        }))
      }

      this.$emit('update:emails', emails)
    },

    addEmail() {
      this.emails.push({
        id: 0,
        email: '',
        description: '',
        user_id: this.userId,
        created: true,
      })
    },
  },
}
</script>
