var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"agendador"}},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-actions',{ref:"filters",attrs:{"title":"Editar Usuário","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner')],1):_c('div',[_c('validation-observer',{ref:"rulesUser"},[_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":_vm.type === 'user' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"email","disabled":_vm.isSubmiting},on:{"blur":_vm.validateEmail},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.isValidatingEmail)?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary","size":"sm"}},[_c('b-spinner',{attrs:{"small":""}})],1)],1):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Tipo de Pessoa","label-for":"person_type"}},[_c('validation-provider',{attrs:{"rules":_vm.type === 'user' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"person_type","options":_vm.crmPersonType,"disabled":_vm.isSubmiting},model:{value:(_vm.user.person_type),callback:function ($$v) {_vm.$set(_vm.user, "person_type", $$v)},expression:"user.person_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.type === 'user')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Senha","description":"Mínimo de 8 caracteres","label-for":"password"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","disabled":_vm.isSubmiting},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2873392671)})],1)],1)],1):_vm._e(),(_vm.user.person_type === 'pf')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"rules":_vm.type === 'user' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"placeholder":"","options":_vm.maskCpf,"disabled":_vm.isSubmiting},model:{value:(_vm.user.cpf),callback:function ($$v) {_vm.$set(_vm.user, "cpf", $$v)},expression:"user.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4140465317)})],1)],1)],1):_vm._e(),(_vm.user.person_type === 'pj')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"cnpj"}},[_c('validation-provider',{attrs:{"rules":_vm.type === 'user' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"placeholder":"","options":_vm.maskCnpj,"disabled":_vm.isSubmiting},model:{value:(_vm.user.cnpj),callback:function ($$v) {_vm.$set(_vm.user, "cnpj", $$v)},expression:"user.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1303473125)})],1)],1)],1):_vm._e(),(_vm.user.person_type === 'pj')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"company_name"}},[_c('validation-provider',{attrs:{"rules":_vm.type === 'user' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.user.company_name),callback:function ($$v) {_vm.$set(_vm.user, "company_name", $$v)},expression:"user.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2375295399)})],1)],1)],1):_vm._e(),(_vm.user.person_type === 'pj')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Posição na empresa","label-for":"position_in_company"}},[_c('validation-provider',{attrs:{"rules":_vm.type === 'user' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.user.position_in_company),callback:function ($$v) {_vm.$set(_vm.user, "position_in_company", $$v)},expression:"user.position_in_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3682171297)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('user-edit-phones',{ref:"userEditPhones",attrs:{"phones":_vm.user.phones,"min":_vm.creatingUser && _vm.type === 'user' ? 1 : 0,"user-id":_vm.user.id,"is-submiting":_vm.isSubmiting},on:{"update:phones":function($event){return _vm.$set(_vm.user, "phones", $event)}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('user-edit-emails',{ref:"UserEditEmails",staticClass:"mt-2",attrs:{"emails":_vm.user.emails,"user-id":_vm.user.id,"is-submiting":_vm.isSubmiting},on:{"update:emails":function($event){return _vm.$set(_vm.user, "emails", $event)}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputColsAdrress}},[_c('user-edit-addresses',{ref:"UserEditAddresses",staticClass:"mt-2",attrs:{"addresses":_vm.user.addresses,"user-id":_vm.user.id,"is-submiting":_vm.isSubmiting},on:{"update:addresses":function($event){return _vm.$set(_vm.user, "addresses", $event)}}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"ml-2 text-right",attrs:{"disabled":_vm.isSubmiting},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.inModal ? 'Fechar' : 'Voltar')+" ")]),_c('b-button',{staticClass:"ml-2 text-right",attrs:{"disabled":_vm.isSubmiting},on:{"click":_vm.saveUser}},[_vm._v(" Salvar ")])],1)],1)],1)],1)]),(_vm.type === 'user' && _vm.accountId === null)?_c('account-list',{attrs:{"show-filters":false,"user-id":parseInt(_vm.userId, 10)}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }