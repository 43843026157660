<template xmlns:b-row="http://www.w3.org/1999/html">
  <section id="crm-user-edit-phones">
    <validation-observer ref="rulesUser">
      <b-row
        v-for="(phone, index) in activeInternalPhones"
        :key="`phone-${index}`"
      >
        <b-col cols="12">
          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="index === 0 ? 'Telefone' : ''"
                :label-for="`phone-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <cleave
                    v-model="phone.full_number"
                    class="form-control"
                    :class="{'line-through': phone.removed}"
                    placeholder="DDD + Número"
                    :options="String(phone.full_number).match(/[0-9]*/g).join('').length === 10 ? maskPhone8 : maskPhone9"
                    :disabled="isSubmiting || phone.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group
                :label="index === 0 ? 'Descrição' : ''"
                :label-for="`description-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-input
                    :id="`description-${index}`"
                    v-model="phone.description"
                    :class="{'line-through': phone.removed}"
                    :disabled="isSubmiting || phone.removed === true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button
                variant="outline-primary"
                :class="{ 'mt-2': index === 0 }"
                @click="removePhone(phone)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </validation-observer>

    <b-button
      size="sm"
      @click="addPhone"
    >
      Adicionar telefone
    </b-button>

  </section>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import requestErrorMessage from '@/mixins/requestErrorMessage'
import Cleave from 'vue-cleave-component'
import {
  maskPhone8, maskPhone9,
} from '@/utils/masks'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BRow,
    BCol,
    Cleave,
  },
  mixins: [toasts, swall, requestErrorMessage],

  props: {
    min: {
      type: Number,
      required: false,
      default: 0,
    },

    phones: {
      type: Array,
      default: () => [],
      required: true,
    },

    userId: {
      type: Number,
      required: true,
    },

    isSubmiting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isRemoving: false,
      userData: null,
      maskPhone8,
      maskPhone9,
      internalPhones: null,
    }
  },
  computed: {
    internalPhonesString() {
      return JSON.stringify(this.internalPhones)
    },

    activeInternalPhones() {
      return this.internalPhones
    },
  },

  watch: {
    internalPhonesString(newValues, oldValues) {
      const newV = JSON.parse(newValues)
      const oldV = JSON.parse(oldValues)
      if (oldV) {
        newV.forEach((item, index) => {
          if (JSON.stringify(item) !== JSON.stringify(oldV[index])) {
            this.internalPhones[index].changed = true
          }
        })
      }

      this.$emit('update:phones', newV.map(m => ({
        ...m,
        ddd: m.full_number.substring(0, 2),
        number: m.full_number.substring(2),
      })))
    },
  },

  created() {
    this.internalPhones = JSON.parse(JSON.stringify(this.phones.map(m => ({
      ...m,
      full_number: `${m.ddd}${m.number}`,
    }))))
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    if (this.min > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.min; i++) {
        this.addPhone()
      }
    }
  },

  methods: {
    removePhone(phone) {
      let phones = []
      if (phone.created ?? false) {
        phones = this.internalPhones.filter(f => f !== phone)
      } else {
        phones = this.internalPhones.map(m => ({
          ...m,
          removed: m.removed || m === phone,
        }))
      }

      if (this.min > 0 && phones.length < this.min) {
        this.swallError('Ops!', 'Informe pelo menos 1 telefone', 'Fechar')
      } else {
        this.internalPhones = phones
        this.$emit('update:phones', JSON.parse(JSON.stringify(phones)))
      }
    },

    addPhone() {
      this.internalPhones.push({
        id: 0,
        ddd: '',
        number: '',
        description: '',
        user_id: this.userId,
        full_number: '',
        created: true,
      })
      this.$emit('update:phones', JSON.parse(JSON.stringify(this.internalPhones)))
    },
  },
}
</script>

<style lang="scss">
  .line-through{
    text-decoration: line-through;
  }
</style>
