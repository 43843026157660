<template xmlns:b-row="http://www.w3.org/1999/html">
  <section id="agendador">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Editar Usuário"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <div
            v-if="isLoading"
            class="text-center"
          >
            <b-spinner />
          </div>
          <div v-else>
            <validation-observer ref="rulesUser">
              <b-row>
                <b-col :cols="inputCols">
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="user.name"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  :cols="inputCols"
                >

                  <b-form-group
                    label="E-mail"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="type === 'user' ? 'required' : ''"
                    >
                      <b-input-group>
                        <b-form-input
                          id="email"
                          v-model="user.email"
                          :disabled="isSubmiting"
                          @blur="validateEmail"
                        />
                        <b-input-group-append
                          v-if="isValidatingEmail"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                          >
                            <b-spinner
                              small
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col :cols="inputCols">
                  <b-form-group
                    label="Tipo de Pessoa"
                    label-for="person_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="type === 'user' ? 'required' : ''"
                    >
                      <b-form-select
                        id="person_type"
                        v-model="user.person_type"
                        :options="crmPersonType"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="type === 'user'">
                <b-col :cols="inputCols">
                  <b-form-group
                    label="Senha"
                    description="Mínimo de 8 caracteres"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                    >
                      <b-form-input
                        id="password"
                        v-model="user.password"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="user.person_type === 'pf'">
                <b-col :cols="inputCols">
                  <b-form-group
                    label="CPF"
                    label-for="cpf"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="type === 'user' ? 'required' : ''"
                    >
                      <cleave
                        v-model="user.cpf"
                        class="form-control"
                        placeholder=""
                        :options="maskCpf"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="user.person_type === 'pj'">
                <b-col :cols="inputCols">
                  <b-form-group
                    label="CNPJ"
                    label-for="cnpj"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="type === 'user' ? 'required' : ''"
                    >
                      <cleave
                        v-model="user.cnpj"
                        class="form-control"
                        placeholder=""
                        :options="maskCnpj"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="user.person_type === 'pj'">
                <b-col :cols="inputCols">
                  <b-form-group
                    label="Empresa"
                    label-for="company_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="type === 'user' ? 'required' : ''"
                    >
                      <b-form-input
                        id="name"
                        v-model="user.company_name"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="user.person_type === 'pj'">
                <b-col :cols="inputCols">
                  <b-form-group
                    label="Posição na empresa"
                    label-for="position_in_company"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="type === 'user' ? 'required' : ''"
                    >
                      <b-form-input
                        id="name"
                        v-model="user.position_in_company"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col :cols="inputCols">
                  <user-edit-phones
                    ref="userEditPhones"
                    :phones.sync="user.phones"
                    :min="creatingUser && type === 'user' ? 1 : 0"
                    :user-id="user.id"
                    :is-submiting="isSubmiting"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col :cols="inputCols">
                  <user-edit-emails
                    ref="UserEditEmails"
                    class="mt-2"
                    :emails.sync="user.emails"
                    :user-id="user.id"
                    :is-submiting="isSubmiting"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col :cols="inputColsAdrress">
                  <user-edit-addresses
                    ref="UserEditAddresses"
                    class="mt-2"
                    :addresses.sync="user.addresses"
                    :user-id="user.id"
                    :is-submiting="isSubmiting"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-button
                    class="ml-2 text-right"
                    :disabled="isSubmiting"
                    @click="back"
                  >
                    {{ inModal ? 'Fechar' : 'Voltar' }}
                  </b-button>

                  <b-button
                    class="ml-2 text-right"
                    :disabled="isSubmiting"
                    @click="saveUser"
                  >
                    Salvar
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </div>

        </b-card-actions>

        <account-list
          v-if="type === 'user' && accountId === null"
          :show-filters="false"
          :user-id="parseInt(userId, 10)"
        />

      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BSpinner,
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import CrmService from '@/services/crmService'
import UserEditPhones from '@/views/crm/user/UserEditPhones.vue'
import UserEditEmails from '@/views/crm/user/UserEditEmails.vue'
import AccountList from '@/views/crm/account/AccountList.vue'
import UserEditAddresses from '@/views/crm/user/UserEditAddresses.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import requestErrorMessage from '@/mixins/requestErrorMessage'
import { crmActivePlanTypes, crmPersonType } from '@/utils/options'
import Cleave from 'vue-cleave-component'
import {
  maskCpf, maskCnpj,
} from '@/utils/masks'

export default {
  components: {
    BSpinner,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormSelect,
    BButton,
    BCardActions,
    BRow,
    BCol,
    BBreadcrumb,
    UserEditPhones,
    UserEditEmails,
    UserEditAddresses,
    AccountList,
    BInputGroup,
    BInputGroupAppend,
    Cleave,
  },
  mixins: [toasts, swall, requestErrorMessage],

  props: {
    userId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    accountId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'user',
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Editar Usuário',
          active: true,
        },
      ],
      crmActivePlanTypes,
      crmPersonType,
      isSubmiting: false,
      isValidatingEmail: false,
      isLoading: false,
      isRemoving: false,
      user: {
        id: 0,
        account_id: null,
        name: null,
        email: '',
        phones: [],
        addresses: [],
        demographic: [],
        emails: [],
        password: '',
        password_check: '',
        active_plan_type: null,
        person_type: '',
      },
      userData: null,
      maskCpf,
      maskCnpj,
    }
  },

  computed: {
    creatingUser() {
      return this.userId !== null && this.userId === 0
    },

    inputCols() {
      return this.inModal ? 10 : 8
    },

    inputColsAdrress() {
      return this.inModal ? 12 : 10
    },

    inModal() {
      return this.userId !== null && this.$route.params.userId === undefined
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.showUser()
  },

  methods: {
    async validateEmail() {
      if (this.user.email.length > 5) {
        let data = {}
        let total = {}

        this.isSubmiting = true
        this.isValidatingEmail = true

        // verifica se o e-mail já esta na conta
        data = { email: this.user.email, permission_type: this.type, account_id: this.accountId }
        const responseClient = await CrmService.listUsers(data)
        if (responseClient) {
          total = responseClient.data.users.data.filter(f => f.id !== this.user.id)
          if (total.length > 0) {
            this.swallError('Atenção', 'E-mail digitado já é usuário desta conta', 'OK')
            this.isSubmiting = false
            this.isValidatingEmail = false
            return
          }
        }

        // verifica se o e-mail já tem conta commercia/crm
        data = { email: this.user.email, permission_type: this.type }
        CrmService.listUsers(data).then(response => {
          total = response.data.users.data.filter(f => f.id !== this.user.id)
          if (total.length > 0) {
            this.swallError('Atenção', 'E-mail digitado já tem conta no CRM, use o botão "Vincular Usuário"', 'OK')
          }
        }).catch(error => {
          this.swallError('Erro!', error.response.data.message, 'Fechar')
        }).finally(() => {
          this.isSubmiting = false
          this.isValidatingEmail = false
        })
      }
    },

    back() {
      if (this.inModal) {
        this.$emit('close', true)
      } else {
        this.$router.push({ name: 'crm-user-list' })
      }
    },

    editUser() {
      this.isSubmiting = true

      return CrmService.putUser(this.user).then(response => {
        if (response.data.user) {
          this.toastSuccess('Sucesso!', 'Usuário alterado!')
          this.showUser()
          this.$emit('saved', true)
        } else {
          this.swallError('Erro!', 'Não foi possível alterar o usuário', 'Fechar')
        }
      }).catch(error => {
        this.swallError('Erro!', error.response.data.message, 'Fechar')
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    createUser() {
      this.isSubmiting = true

      return CrmService.postUser(this.user).then(response => {
        if (response.data.user) {
          this.toastSuccess('Sucesso!', 'Usuário criado!')
          this.showUser()
          this.$emit('saved', true)
        } else {
          this.swallError('Erro!', 'Não foi possível criar o usuário', 'Fechar')
        }
      }).catch(error => {
        this.swallError('Erro!', error.response.data.message, 'Fechar')
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    saveUser() {
      if (this.accountId) {
        this.user.account_id = this.accountId
      }

      return this.$refs.rulesUser.validate().then(success => {
        if (success) {
          if (this.user.id === 0) {
            this.createUser()
          } else {
            this.editUser()
          }
        } else {
          this.toastDanger('Erros de validação', 'Preencha todos os campos obrigatórios')
        }
      })
    },

    showUser() {
      const userId = this.userId ? this.userId : this.$route.params.userId
      if (userId) {
        this.isLoading = true
        CrmService.showUser(userId, { append: ['emails', 'phones', 'addresses'] }).then(response => {
          if (response.data !== null) {
            this.user = response.data.user
            if (this.user.emails) {
              this.user.emails = this.user.emails.filter(m2 => m2.main !== true)
            }
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
.line-through{
  text-decoration: line-through;
}
</style>
